<template>
    <div class="newkf">
        <div class="newkf-header">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/admin/newstorecustomer' }">新店铺客服管理</el-breadcrumb-item>
                <el-breadcrumb-item>新客服管理</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px">
                <IndustrySelection ref="IndustrySelection" @selectListChange="selectListChange" text="行业分类：" :selectList="selectList"></IndustrySelection>
                <div>
                    <el-button class="btn-blue" @click="sendBtn" style="display: inline-block;">发送</el-button>
                    <el-button class="btn-blue" @click="addBtn">创建客服数据</el-button>
                </div>
            </div>
        </div>
        <el-table ref="multipleTable" selection :data="serviceData" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium" :header-cell-style="{
        fontWeight: 'normal',
        height: '60px',
        color: '#13131B',
        background: '#F2F3FA',}" :cell-style="{ fontSize: '14px', color: '#343441' }">
            <el-table-column type="selection"></el-table-column>
            <el-table-column align="center" prop="name" label="客服数据名称">
            </el-table-column>
            <el-table-column prop="get_category_name.name" align="center" label="行业分类"></el-table-column>
            <el-table-column label="操作" align="center" width="200" :resizable="false">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue" @click="copyLink(scope.row)">复制</el-link>&nbsp;
                    <el-link :underline="false" class="link-blue" @click="editLink(scope.row)">编辑</el-link>&nbsp;
                    <el-link :underline="false" type="danger" @click="deleteLink(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pager-center"
                style="margin-top: 10px"
                :current-page="newKfPages.currentPageNum"
                :page-size="newKfPages.eachPageNum"
                :total="newKfPages.total"
                layout="prev, pager, next, jumper"
                @current-change="currentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {getNewKeFuListData, postDelKefuData, getNewkefuCopy} from "@/utils/apis";
    import IndustrySelection from "../../components/admin/IndustrySelection";
    import {getNewkefuSend} from "../../utils/apis";

    export default {
        neme: "NewStoreCustomerData",
        components: {
            IndustrySelection
        },
        data() {
            return {
                serviceData: [],
                newKfPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                selectList: [],
                selectVal: void 0,
            };
        },

        created() {
            this.getListData();
            this.getKeFuGetIC()
        },

        activated() {
            this.$refs.IndustrySelection.resetSelectListvalue()
            this.getListData()
        },

        methods: {
            // 点击发送
            async sendBtn(){
                let id =this.$refs.multipleTable.selection.map((item) => item.id);
                if(id && id.length > 0){
                    const result = await this.$common.sendData(getNewkefuSend, { id: id.reverse().join('#') });
                    result && this.getListData();
                }else{
                    this.$message.error("请勾选需要发送的数据！")
                }
            },
            selectListChange(id) {
                this.selectVal = id;
                this.getListData(id)
            },
            // 获取行业分类
            getKeFuGetIC() {
                this.$http.axiosGetBy(this.$api.categoryList, {}, (res) => {
                    if (res.code === 200) {
                        this.selectList = res.data;
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
            },
            // 获取图表数据
            getListData(id) {
                let params = {
                    page: this.newKfPages.currentPageNum,
                    limit: this.newKfPages.eachPageNum,
                    type: "page",
                    category_id: id ? id : ''
                };
                getNewKeFuListData(params).then((res) => {
                    if (res.code === 200) {
                        this.serviceData = res.data.list;
                        this.newKfPages.total = res.data.total;
                    }
                });
            },
            // 页脚
            currentChange(val) {
                this.newKfPages.currentPageNum = val;
                this.getListData(this.selectVal);
            },
            // 点击创建客服数据
            addBtn() {
                this.$router.push("/admin/creatnewstorecustomer");
            },
            // 表格点击编辑
            editLink(row) {
                this.$router.push({
                    path: "/admin/creatnewstorecustomer",
                    query: {
                        id: row.id,
                    },
                });
            },
            // 点击复制
            copyLink(val) {
                this.$confirm(`是否复制一个副本？`, {
                    confirmButtonText: "确定复制",
                    cancelButtonText: "取消",
                    confirmButtonClass: 'btn-blue',
                    type: "warning",
                }).then(() => {
                    let params = {
                        id: val.id,
                    };
                    getNewkefuCopy(params)
                        .then((res) => {
                            if (res.code == 200) {
                                this.$message({
                                    message: res.msg,
                                    type: "success",
                                    duration: 1000,
                                    onClose: () => {
                                        this.getListData(this.selectVal);
                                    },
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: "error",
                                    duration: 1000,
                                    onClose: () => {
                                        this.getListData(this.selectVal);
                                    },
                                });
                            }
                        })
                        .catch((err) => {
                            console.log("err", err);
                        });
                }).catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
            },
            // 表格点击删除
            deleteLink(row) {
                this.$confirm(`此操作将永久删除该试卷，是否继续`, "删除", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    confirmButtonClass: 'btn-blue',
                    type: "warning",
                })
                    .then(() => {
                        let params = {
                            kefu_id: row,
                        };
                        postDelKefuData(params)
                            .then((res) => {
                                if (res.code == 200) {
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                        duration: 1000,
                                        onClose: () => {
                                            this.getListData(this.selectVal);
                                        },
                                    });
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                        duration: 1000,
                                        onClose: () => {
                                            this.getListData(this.selectVal);
                                        },
                                    });
                                }
                            })
                            .catch((err) => {
                                console.log("err", err);
                            });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
        },
    };
</script>

<style scoped lang="scss">
    .newkf {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .newkf-header {
            /*display: flex;*/
            /*align-items: center;*/
            /*justify-content: space-between;*/
        }
    }

</style>
